import {
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxProps,
  Flex,
  HStack,
  Skeleton,
  Spinner,
  TableProps,
  VStack
} from '@chakra-ui/react'
import { forwardRef, ReactNode, useMemo } from 'react'
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp
} from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import {
  CellValue,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table'
import { H5, Text } from '../../typography'
import Card from '../Card'
import BottomSection from '../Card/CardFooter'
import ErrorBoundary from '../ErrorBoundary'
import SearchBar from '../SearchBar'
import { StyledTable, TableCell, TableHead, TableIconButton, TableRow } from './styles'

const IndeterminateCheckbox = forwardRef<
  HTMLInputElement,
  CheckboxProps & { indeterminate?: boolean }
>(({ checked, indeterminate, ...rest }, ref) => {
  return (
    <Checkbox
      ref={ref}
      isChecked={checked}
      isIndeterminate={indeterminate}
      __css={{
        '.chakra-checkbox__control': {
          backgroundColor: 'white',

          '&[data-checked], &[data-indeterminate]': {
            backgroundColor: 'blue.500'
          }
        }
      }}
      {...rest}
    />
  )
})

type Props<D extends object = {}> = TableProps & {
  data: any
  pageSize?: number
  tableHeading?: ReactNode
  columns: Column<D>[]
  onRowClick?: (row: Row<D>) => void
  isLoading?: boolean
  renderActionsLeft?: (args: { selectedFlatRows: any[] }) => ReactNode
  renderActionsRight?: () => ReactNode
  onSearch?: (text: string) => void
  onClickNext?: () => Promise<any>
  rowSelect?: boolean
  noDataComponent?: React.ReactNode
}

const Table = <D extends {}>({
  columns,
  data,
  tableHeading,
  pageSize: initialPageSize,
  onRowClick,
  isLoading,
  renderActionsLeft,
  renderActionsRight,
  onSearch,
  onClickNext,
  rowSelect,
  noDataComponent
}: Props<D>): JSX.Element => {
  const tableColumns = useMemo(() => columns, [columns])

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    rows,
    selectedFlatRows
  } = useTable<D>(
    {
      columns: tableColumns,
      data: data,
      initialState: { pageIndex: 0, pageSize: initialPageSize }
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((allColumns) =>
        rowSelect
          ? [
              {
                id: 'selection',
                Header: ({ getToggleAllRowsSelectedProps }) => (
                  <Center>
                    <IndeterminateCheckbox
                      margin={-6}
                      padding={6}
                      {...getToggleAllRowsSelectedProps()}
                    />
                  </Center>
                ),
                Cell: ({ row }: CellValue) => (
                  <Center>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                      padding={6}
                      margin={-6}
                    />
                  </Center>
                ),
                width: 10
              },
              ...allColumns
            ]
          : [...allColumns]
      )
    }
  )

  const numOfSelectedRows = selectedFlatRows.length

  const renderTableActionsLeft = () => (
    <Flex>
      {tableHeading && (
        <H5 fontWeight="bold" mr={4}>
          {tableHeading}
        </H5>
      )}
      {rowSelect && rows.length > 0 && (
        <ButtonGroup isDisabled={numOfSelectedRows < 1}>
          {renderActionsLeft &&
            renderActionsLeft({
              selectedFlatRows: selectedFlatRows.map((d: Row<CellValue>) => d.original)
            })}
        </ButtonGroup>
      )}
    </Flex>
  )

  const renderTableActionsRight = () => (
    <Flex>
      {isLoading && <Spinner color="brand.300" />}
      {!!onSearch && <SearchBar onSearch={onSearch} />}
      {renderActionsRight && renderActionsRight()}
    </Flex>
  )

  const renderTable = () => {
    return (
      <Card flexDirection="column" flex={1} maxWidth="100%" width="100%">
        <StyledTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup: HeaderGroup<D>) => (
              <Flex
                flex={1}
                flexDirection="row"
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id}
              >
                {headerGroup.headers.map((column: ColumnInstance<D>) => (
                  <TableCell
                    p={4}
                    {...column.getHeaderProps()}
                    justifyContent="space-between"
                    {...column.getSortByToggleProps()}
                    key={column.id}
                  >
                    <Text fontWeight="bold">{column.render('Header')}</Text>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ChevronDown size={20} />
                      ) : (
                        <ChevronUp size={20} />
                      )
                    ) : (
                      ''
                    )}
                  </TableCell>
                ))}
              </Flex>
            ))}
          </TableHead>
          <Flex flexDirection="column">
            {page.map((row) => {
              prepareRow(row)
              return (
                <TableRow
                  {...row.getRowProps()}
                  flexDirection="row"
                  onClick={() => onRowClick && onRowClick(row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        justifyContent="flex-start"
                        p={4}
                        {...cell.getCellProps()}
                        key={cell.row.index}
                      >
                        {cell.render('Cell')}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </Flex>
          {data.length < 1 && (
            <Center as={Card} flex={1} maxWidth="100%" width="100%">
              {noDataComponent}
            </Center>
          )}
        </StyledTable>
        {data.length > 0 && (
          <BottomSection justifyContent="space-between" flexDirection="row">
            <Flex flexDirection="row">
              <TableIconButton mr={2} onClick={() => gotoPage(0)} isDisabled={!canPreviousPage}>
                <ChevronsLeft size={20} />
              </TableIconButton>
              <TableIconButton mr={2} isDisabled={!canPreviousPage} onClick={() => previousPage()}>
                <ChevronLeft size={20} />
              </TableIconButton>
            </Flex>
            <Flex justifyContent="center" alignItems="center">
              <Text mr={4}>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{' '}
              </Text>
              {!isTabletOrMobile && (
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                  }}
                >
                  {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              )}
            </Flex>
            <Flex flexDirection="row">
              <TableIconButton
                ml={2}
                isDisabled={!canNextPage}
                onClick={async () => {
                  onClickNext && (await onClickNext())
                  nextPage()
                }}
              >
                <ChevronRight size={20} />
              </TableIconButton>
              <TableIconButton
                ml={2}
                onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
                isDisabled={!canNextPage}
              >
                <ChevronsRight size={20} />
              </TableIconButton>
            </Flex>
          </BottomSection>
        )}
      </Card>
    )
  }

  return (
    <VStack spacing={4} flex={1} height="100%" width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Flex>{renderTableActionsLeft()}</Flex>
        <Flex>{renderTableActionsRight()}</Flex>
      </HStack>
      <ErrorBoundary>{renderTable()}</ErrorBoundary>
    </VStack>
  )
}

export default Table

Table.defaultProps = {
  isLoading: false,
  rowSelect: false,
  tableHeading: null,
  onClickNext: () => null,
  onRowClick: () => null,
  renderActionsLeft: () => null,
  renderActionsRight: () => null,
  onSearch: null,
  pageSize: 10,
  noDataComponent: (
    <Center flexDirection="column" minHeight={120} opacity={0.4} padding={8}>
      <Skeleton height={6} marginBottom={1} rounded="2xl" width={100} />
      {[1, 2].map((value) => (
        <Skeleton
          key={value}
          height={3}
          marginBottom={0}
          marginTop={2}
          rounded="md"
          width={`${288 / value}px`}
        />
      ))}
    </Center>
  )
}
