// v4 causes dynamic image imports to be objects: https://github.com/facebook/create-react-app/issues/9992
// TODO: Fix linting issues
/* eslint-disable import/no-anonymous-default-export */
export default {
  bg: require('../assets/images/core-bg.jpg')?.default,
  whiteTextLogo: require('../assets/images/logo-white.png')?.default,
  blackTextLogo: require('../assets/images/logo-black.png')?.default,
  404: require('../assets/images/404.svg')?.default,
  noData: require('../assets/images/no-data.png')?.default
}
