import { ApolloClient, from, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { errorLogger, queryLogger } from 'the-apollo-utilities'
import { cache } from './cache'
import { REACT_APP_API_HOST } from './constants'
import { fetchJwt } from './utils'

const httpLink = new HttpLink({
  uri: REACT_APP_API_HOST + '/graphql'
})

const retryLink = new RetryLink()

const authLink = setContext((_, { headers }) => {
  // get the authentication token
  const token = fetchJwt()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ graphQLErrors }) => {
  if (
    graphQLErrors &&
    (graphQLErrors[0].message === 'Invalid token.' || graphQLErrors[0].message === 'User Not Found')
  ) {
    // TODO: Logout user
  }
})

let clientLink = from([errorLink, authLink, retryLink, httpLink])

if (process.env.NODE_ENV !== 'production') {
  clientLink = from([errorLogger, queryLogger, clientLink])
}

const client = new ApolloClient({
  cache,
  link: clientLink
})

export default client
