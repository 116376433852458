import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'
import { FC } from 'react'
import { H5 } from '../../typography'

interface Props extends ModalProps {
  isOpen: boolean
  title: string
  actions?: React.ReactNode
  maxWidth?: number
}

/**
 * @render react
 * @name RModal component
 * @description RModal component.
 */

const RModal: FC<Props> = ({
  actions,
  children,
  isOpen,
  onClose,
  title,
  colorScheme,
  maxWidth,
  ...rest
}) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} {...rest}>
      <ModalOverlay />
      <ModalContent borderRadius="xl" marginX={6} maxWidth={maxWidth}>
        <ModalHeader
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          borderBottomWidth={1}
        >
          <H5 mb={0}>{title}</H5>
          <ModalCloseButton rounded="full" top="auto" />
        </ModalHeader>
        <ModalBody paddingY={6}>{children}</ModalBody>
        {actions && (
          <ModalFooter
            backgroundColor="gray.50"
            borderBottomRadius="xl"
            borderColor="gray.100"
            borderTopWidth={1}
          >
            {actions}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

RModal.defaultProps = {
  motionPreset: 'slideInBottom'
}

export default RModal
