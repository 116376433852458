import { Button, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as React from 'react'
import { LogOut } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import { useAppContext } from '../../context/AppProvider'
import { useAuthContext } from '../../context/AuthProvider/index'
import { useSelfQuery } from '../../generated/graphql'
import { Text } from '../../typography'
import Breadcrumbs from '../Breadcrumbs'
import SideBarButton from '../SideBar/SideBarButton'

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

type HeaderContProps = SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  }

const HeaderCont = styled(motion.div)<HeaderContProps>`
  ${space};
  ${color};
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1290;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '64px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const BreadCrumbCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 40em) {
    display: none;
  }
`

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const history = useHistory()
  const { drawerOpen, toggleDrawer } = useAppContext()
  const { logout } = useAuthContext()

  const { data: selfData } = useSelfQuery()
  const user = selfData?.self

  const handleLogout = () => {
    logout && logout()
    history.push('/')
  }

  return (
    <HeaderCont pr={4} pl={drawerOpen ? 'calc(186px + 1rem)' : '1rem'} {...rest}>
      <BreadCrumbCont>
        <Breadcrumbs />
      </BreadCrumbCont>
      {isTabletOrMobile && <SideBarButton color="black" open={drawerOpen} onClick={toggleDrawer} />}
      <Flex flexDirection="row">
        <Flex flexDirection="row" justifyContent="center" alignItems="center">
          <Text mb={0} mr={4} fontWeight="bold">
            {user?.email}
          </Text>
          <Button size="sm" onClick={handleLogout}>
            <Flex marginRight={2}>
              <LogOut size={18} />
            </Flex>
            Logout
          </Button>
        </Flex>
      </Flex>
    </HeaderCont>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'white'
}
